import React, { useState, useEffect, useRef } from "react";
function useAudioPlayer() {
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const audioElementRef = useRef(null);

  useEffect(() => {
    const audioElement = audioElementRef.current;

    if (isPlaying) {
      audioElement.play();
    } else {
      audioElement.pause();
    }
  }, [isPlaying]);

  const isNotPlaying = () => {
    setIsPlaying(false);
  };

  useEffect(() => {
    if (currentTime == duration) {
      setIsPlaying(false);
    }
  }, [currentTime]);

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = (event) => {
    if (event.currentTarget) {
      if (event.currentTarget.currentTime >= event.currentTarget.duration) {
        setCurrentTime(0);
        event.currentTarget.currentTime = 0;
        isNotPlaying();
      } else {
        setCurrentTime(event.currentTarget.currentTime);
      }
    }
  };

  const handleLoadedMetadata = (event) => {
    if (
      audioElementRef.current &&
      audioElementRef.current.duration &&
      !isNaN(audioElementRef.current.duration) &&
      audioElementRef.current.duration > 0
    ) {
      setDuration(audioElementRef.current.duration);
    }
  };

  const handleSliderChange = (event, value) => {
    if (audioElementRef.current) {
      audioElementRef.current.currentTime = event.target.value;
      setCurrentTime(event.target.value);
    }
  };

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = Math.floor(timeInSeconds % 60);
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  return {
    isPlaying: isPlaying,
    notPlaying: () => setIsPlaying(false),
    currentTime: currentTime,
    duration: duration,
    audioElementRef: audioElementRef,
    handlePlayPause: handlePlayPause,
    handleTimeUpdate: handleTimeUpdate,
    handleLoadedMetadata: handleLoadedMetadata,
    handleSliderChange: handleSliderChange,
    formatTime: formatTime,
    setDuration: setDuration,
  };
}

export default useAudioPlayer;
