import React from 'react';
import { Typography } from "@mui/material";
import './CustomCancelSave.css';

const CustomActionButton = ({ children, text }) => {
  return (
    <div>
        <div>{children}</div>
        <div>
        <Typography 
         sx={{
            marginTop: '0px'
         }}
         gutterBottom>{text}</Typography>
        </div>
    </div>
  )
}

export default CustomActionButton
